@import 'app/styles/common/component.scss';

.form-drawer {
  position: fixed;
  top: 102px;
  right: 0;
  overflow-y: scroll;

  &__login {
    padding-bottom: 20px;
    border-bottom: 2px solid $c-white;
    .form-header {
      margin-bottom: 40px;
    }
  }

  &__sign-up {
    .toogle-button {
      line-height: 1.15;
    }
  }

  .form-header {
    @include breakpoint('sm-down') {
      margin: 40px 0 0 35px;
    }
  }

  .ant-form-item {
    &:nth-child(2) {
      margin: 30px 0 10px;
    }
  }

  @include breakpoint('sm-down') {
    top: 0;
    bottom: 0;
  }

  .ant-drawer-body {
    padding: 10px 60px;

    @include breakpoint('sm-down') {
      padding: 15px 30px;
    }
  }
  .ant-drawer-content {
    .ant-drawer-header-close-only {
      padding-right: 10px;
    }

    &-wrapper {
      height: 100%;
      @include breakpoint('sm-down') {
        width: 100% !important;
      }
    }
  }
}
